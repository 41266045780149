@font-face {
  font-family: 'ARMTBOLD';
  src: url('./arial-rounded-mt-bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@keyframes blink {
  0% {
    border-color: transparent;
  }

  50% {
    border-color: black;
  }

  100% {
    border-color: transparent;
  }
}

@keyframes MAXiN {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes bouncIIN {
  0% {
    opacity: 0;
    transform: scale(0.5) translateY(-100%) rotate(-20deg);
  }

  60% {
    opacity: 1;
    transform: scale(1.2) translateY(0) rotate(-20deg);
  }

  80% {
    transform: scale(0.95) translateY(0) rotate(-20deg);
  }

  100% {
    transform: scale(1) translateY(0) rotate(-20deg);
  }
}

@keyframes bouncIItel {
  0% {
    opacity: 0;
    transform: scale(0.5) translateY(-100%) rotate(-20deg);
  }

  60% {
    opacity: 1;
    transform: scale(1.2) translateY(0) rotate(0deg);
  }

  80% {
    transform: scale(0.95) translateY(0) rotate(-20deg);
  }

  100% {
    transform: scale(1) translateY(0) rotate(0deg);
  }
}

@keyframes bouncIIN2 {
  0% {
    opacity: 0;
    transform: scale(0.5) translateY(-100%) rotate(-20deg);
  }

  60% {
    opacity: 1;
    transform: scale(1.2) translateY(0) rotate(-10deg);
  }

  80% {
    transform: scale(0.95) translateY(0) rotate(-20deg);
  }

  100% {
    transform: scale(1) translateY(0) rotate(0deg);
  }
}

@keyframes float3d1 {

  0%,
  100% {
    transform: translate(0, 0) rotateX(0deg) rotateY(0deg);
  }

  25% {
    transform: translate(-10px, -10px) rotateX(3deg) rotateY(3deg);
  }

  50% {
    transform: translate(10px, -10px) rotateX(-3deg) rotateY(-3deg);
  }

  75% {
    transform: translate(-10px, 10px) rotateX(3deg) rotateY(-3deg);
  }
}

@keyframes float3d2 {

  0%,
  100% {
    transform: translate(0, 0) rotateX(0deg) rotateY(0deg);
  }

  25% {
    transform: translate(10px, -10px) rotateX(-3deg) rotateY(-3deg);
  }

  50% {
    transform: translate(-10px, 10px) rotateX(3deg) rotateY(3deg);
  }

  75% {
    transform: translate(10px, 10px) rotateX(-3deg) rotateY(3deg);
  }
}

@keyframes float3d3 {

  0%,
  100% {
    transform: translate(0, 0) rotateX(0deg) rotateY(0deg);
  }

  25% {
    transform: translate(-10px, 10px) rotateX(3deg) rotateY(-3deg);
  }

  50% {
    transform: translate(10px, 10px) rotateX(-3deg) rotateY(3deg);
  }

  75% {
    transform: translate(10px, -10px) rotateX(3deg) rotateY(-3deg);
  }
}

@keyframes float3d4 {

  0%,
  100% {
    transform: translate(0, 0) rotateX(0deg) rotateY(0deg);
  }

  25% {
    transform: translate(10px, -10px) rotateX(-3deg) rotateY(-3deg);
  }

  50% {
    transform: translate(10px, -10px) rotateX(-3deg) rotateY(-3deg);
  }

  75% {
    transform: translate(-10px, -10px) rotateX(-3deg) rotateY(-3deg);
  }
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft
}

@-webkit-keyframes jackInTheBox {
  0% {
    opacity: 0;
    -webkit-transform: scale(.1) rotate(30deg);
    transform: scale(.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom
  }

  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg)
  }

  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg)
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes jackInTheBox {
  0% {
    opacity: 0;
    -webkit-transform: scale(.1) rotate(30deg);
    transform: scale(.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom
  }

  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg)
  }

  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg)
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

.animate__jackInTheBox {
  -webkit-animation-name: jackInTheBox;
  animation-name: jackInTheBox
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight
}

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translate(-40px, 130px);
    transform: translate(-40px, 130px);
  }
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translate(-40px, 130px);
    transform: translate(-40px, 130px);
  }
}

.animate__fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05)
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05)
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }
}

.animate__pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out
}

@-webkit-keyframes bounce {

  0%,
  20%,
  53%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(0, -30px, 0) scaleY(1.1);
    transform: translate3d(0, -30px, 0) scaleY(1.1)
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(0, -15px, 0) scaleY(1.05);
    transform: translate3d(0, -15px, 0) scaleY(1.05)
  }

  80% {
    -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transform: translateZ(0) scaleY(.95);
    transform: translateZ(0) scaleY(.95)
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
    transform: translate3d(0, -4px, 0) scaleY(1.02)
  }
}

@keyframes bounce {

  0%,
  20%,
  53%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(0, -30px, 0) scaleY(1.1);
    transform: translate3d(0, -30px, 0) scaleY(1.1)
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(0, -15px, 0) scaleY(1.05);
    transform: translate3d(0, -15px, 0) scaleY(1.05)
  }

  80% {
    -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transform: translateZ(0) scaleY(.95);
    transform: translateZ(0) scaleY(.95)
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
    transform: translate3d(0, -4px, 0) scaleY(1.02)
  }
}

.animate__bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  position: relative;
  padding-top: 70px;
}


* {
  margin: 0;
  padding: 0;
  font-family: "ARMTBOLD", sans-serif;
  font-style: normal;
  box-sizing: border-box;

}

.widthMaxcont {
  width: 1200px;
  position: relative;
  padding: 0 10px;
}

.theMPORMw {
  max-width: 645px;
  margin: auto;
  padding: 20px 0;

}

.lay-header {
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: #003c4d;
  box-shadow: 0px 4px 6px 0px rgb(0 0 0 / 60%);
  position: relative;
  z-index: 9999;

}

.lay-header {
  position: fixed;
  top: 0;
}

/* .lay-presentation, .lay-entetepresFormation {
  padding-top: 70px;
} */

.lay-footer {
  display: flex;
  width: 100%;
  /* min-height: 190px; */
  justify-content: center;
  background-color: #003c4d;
  position: relative;

}

.lay-presentation {
  display: flex;
  width: 100%;
  height: 580px;
  /* overflow: hidden; */
  justify-content: center;
  background-color: white;
  position: relative;

}

.lay-entetepresFormation {
  display: flex;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  background-color: #f7c615;
  position: relative;
  box-shadow: 0px 2px 10px black;
  position: sticky;
  top: 70px;
  z-index: 999;
}

.lay-presAE {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  /* box-shadow: 0px -5px 7px 2px white, inset 1px 5px 3px white; */

}

.lay-criteres {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  background-color: #f7c615;
}

.lay-horaires {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  background-color: #ecf8ff;
  overflow: hidden;
}

.lay-formules {
  display: flex;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  position: relative;
  background-repeat: no-repeat;
}

.lay-reviews {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  /* background-image: url('./assets/img/wallpaper/14202.jpg'); */
}

.lay-formules:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0.1;
  z-index: -1;
  background-image: url('./assets/img/wallpaper/14202.jpg');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.lay-reviews:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0.1;
  z-index: -1;
  background-image: url(./assets/img/wallpaper/route-zp2.jpg);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.lay-contact {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  background-color: #003c4d;
  color: white;
}

.lay-formations {
  display: flex;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  position: relative;
  background-repeat: no-repeat;
  align-items: center;
}

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 15px;
  justify-content: space-between;
}

.logoSOCIETE {
  width: 70px;
}

.thenavH ul {
  display: flex;
  flex-direction: row;
  list-style: none;
}

.thenavH ul li a,
.thenavH ul li {
  padding: 5px;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.211);
  cursor: pointer;
  margin-left: 15px;
  text-decoration: none;
}

.contaLI {
  border-radius: 10px;
  border: 1px solid white;
}

.cutDCSL {
  display: block;
  width: 732px;
  height: 580px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: rotate(0deg);
}

.dCSL {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 477px;
  height: 799px;
  position: relative;
  top: -110px;
  left: 120px;
  overflow: hidden;
  transform: rotate(20deg) scale(1);
  border-right: 3px solid #f7c615;
  border-left: 3px solid #f7c615;
  box-shadow: 2px 3px 7px 0px #00000073;
}

.shadowbottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 510px;
  box-shadow: 4px 0px 9px 11px white;
}

.carslIMG {
  height: 100%;
}

.thegalcsrsl {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0 10px;
}

.infroDcrsl {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
  width: 57%;
  height: 100%;
  z-index: 999;
}

h2 {
  color: #f7c615;
  text-shadow: 0px 0px 1px rgba(40, 40, 40, 0.2);
  text-align: center;
  font-size: 32px;
}

b {
  color: #14689f;
  text-shadow: 1px 1px 1px rgba(40, 40, 40, 0.5);
}

span {
  font-size: 24px;
}

.slog {
  margin-top: 15px;
  text-align: end;
}

.sTxtp {
  margin-bottom: 15px;
  text-align: end;
}

.widMaxIMG {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 584px;
  overflow: hidden;
  transition: all .7s ease;
  transform: rotate(-20deg) translateX(0px) translateY(0px);
}

.ef {
  transform: rotate(-20deg) translateX(0px) translateY(0px);
  animation: bouncIIN 1.5s ease-in-out forwards;
}

.infCarslDes {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
  justify-content: space-between;
}

.ulind {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
}

.ulind ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.ulind ul li {
  position: relative;
  padding: 10px;
}



.CONTcharg {
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  bottom: 0;
  right: 0;
}

.theBTNCHARG {
  height: 3px;
  background-color: #f7c615;
  transition: all 0.05s linear;
}

.THEdesLOO {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  flex-direction: row-reverse;
  align-items: center;
}

.hPrAE {
  font-size: 24px;
  margin-bottom: 35px;
}

.desIMG {
  text-shadow: 1px 1px 1px rgba(155, 155, 155, 0.5);
  text-align: center;
  width: 82%;
  position: absolute;
  transform: translateX(2000px) scale(0);
  transition: all .7s;
  font-family: Moderat, sans-serif !important;
}

.text-pres {
  text-align: justify;
  font-size: 22px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.32);
  font-family: Moderat, sans-serif !important;
}

.spaceTp {
  font-family: Moderat, sans-serif !important;
  margin-bottom: 15px;
}

footer {
  padding: 10px !important;
}

.thesecR {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  position: relative;
}

.thesecR:before,
.thesecR:after {
  border-top: 1px solid #7691ad;
  content: "";
  position: absolute;
  top: 50%;
  width: 42%;
}

.thesecR:after {
  right: 0;
}

.thesecR:before {
  left: 0;
}

.media-footer {
  display: flex;
  flex-direction: row;
  list-style: none;
}

.media-footer li a {
  text-decoration: none;
  color: white;
  margin: 5px;
}

.hiSo {
  display: none;
}

.LogoCPcs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-top: 8px;
}

.LogoCPcs img {
  width: 160px;
  filter: blur(1);
}

.LogoCPcs span {
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 5px;
}

.ScfFot {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 8px;
}

.nuAGR {
  width: 523px;
  text-align: center;
  position: absolute;
  top: 0;
  left: -16px;
}

.rgFooter {
  list-style: none;
  display: flex;
  flex-direction: row;

}

.rgFooter li a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  margin: 8px;
}

.rgFooter li {
  position: relative;
}

.rgFooter li:after {
  display: block;
  position: absolute;
  content: '|';
  top: 0;
  right: -2px;
  color: rgba(255, 255, 255, 0.8);
}

.rgFooter li:last-child:after {
  display: none;
}

.theVitZP {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  width: 500px;
  height: 370px;
  border-radius: 5px;
  overflow: hidden;
}

.forDvitrine {
  width: 100%;
}

.theGpartAE {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  min-height: 490px;
}

.rightAE {
  box-shadow: 2px 2px 10px 2px #0000004a;
  border-radius: 5px;
}

.leftAE {
  display: flex;
  flex-direction: column;
  width: 55%;
  height: 100%;
  /* align-content: center; */
  justify-content: center;
  align-items: center;
}

.theContCritD {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lesdic {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
  margin: 35px 0 0 0;
}

.theDico {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: scale(0);
  margin: 25px;
  filter: drop-shadow(1px 1px 1px #14679f80);
}

.theDico span {
  color: #14689f;
  text-shadow: 1px 1px 1px #14679f80;
}

.thepresInstruc {
  color: #ffffff;
  text-shadow: 1px 1px 1px #14679f80;
  font-size: 22px;
  margin-bottom: 45px;
  text-align: justify;
  width: 80%;
  transition: all .7s;
}

.theContHor {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.allDaysSem {
  /* border: 1px solid gray; */
  margin: 25px;
  border-radius: 5px;
  width: 45%;
  overflow: hidden;
  background: white;
  box-shadow: 6px 5px 11px 3px rgb(0 0 0 / 36%);
}

.allDaysSem h2 {
  padding: 10px 0;
}

.Day {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 15px 15px;
  border-bottom: 1px solid gray;
  box-sizing: border-box;
  overflow: hidden;
}

.Day:last-child {
  border-bottom: none;
}

.times {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}

.infDimaC {
  display: flex;
  width: 390px;
  height: 190px;
  filter: drop-shadow(2px 4px 6px black);
  background-image: url("./assets/bulle/panneauinfo.svg");
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  position: relative;
}

.ocdpld1,
.ocdpld2,
.ocdpld3 {
  position: absolute;
  font-size: 20px;
  color: white;
  text-align: center;
}

.ocdpld1 {
  top: 30px;
  right: 45px;
}

.ocdpld2 {
  top: 69px;
  right: 110px;
}

.ocdpld3 {
  bottom: 57px;
  right: 29px;
}

.theContAvis {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 480px;
}

.GalAvis {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 55%;
  min-height: 400px;
  align-items: center;
  justify-content: center;

}

.avisOne {
  display: flex;
  position: absolute;
  transition: all .5s;
  transform: scale(0);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.NameAndRate {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 7px;
  flex-wrap: wrap;
}

.lavisTTxt {
  text-align: justify;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.211);
  font-size: 22px;
  margin-bottom: 10px;
}

.nameRateur {
  display: flex;
  align-items: center;
  font-size: 22px;
  color: #14689f;
  text-shadow: 1px 1px 1px gray;
}

.star-rating {
  display: flex;
  align-items: center;
  filter: drop-shadow(1px 1px 1px black);
}

.star {
  width: 24px;
  height: 24px;
  background-color: #ccc;
  clip-path: polygon(50% 0%,
      61% 35%,
      98% 35%,
      68% 57%,
      79% 91%,
      50% 70%,
      21% 91%,
      32% 57%,
      2% 35%,
      39% 35%);
  margin-right: 4px;
}

.star.full {
  background-color: gold;
}

.star.half {
  background: linear-gradient(to right, gold 50%, #ccc 50%);
}

.btnGalc {
  background: transparent;
  appearance: none;
  border: none;
  cursor: pointer;
  padding: 10px;
}

.magnetic-div {
  display: flex;
  flex-direction: column;
  transition: transform 0.1s ease-out;
}

.choiceFilCAT {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin: 20px 0;
}

.ligneForfait {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ligneForfaitCode {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 25px;

}

#thetxtbuttonCHXO {
  color: rgb(38 106 167 / 54%);
  font-size: 45px;
  font-weight: 900;
  filter: drop-shadow(1px 1px .2px black);
}

.titFormuu {
  font-size: 44px;
  color: #476c78;

}

#lisSch {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.chXo {
  position: relative;
  display: flex;
  width: 150px;
  overflow: hidden;
  aspect-ratio: 1;
  /* height: 75px; */
  justify-content: center;
  align-items: center;
  margin: 15px;
  padding: 10px;
  border: 1px solid #f7c615;
  /* border-radius: 50px; */
  filter: drop-shadow(1px 1px 2px #4b4b50);
  background: #ffffff;
  cursor: pointer;
  box-sizing: content-box;
}

.cSE {
  background: linear-gradient(180deg, #ffffff, #efd886);
  border: 3.4px solid #38a3ff;
}

.circlepath {
  width: 100%;
  top: 0px;
  right: 0;
  position: absolute;
  height: 75px;
}

#mousOch .circlepath {
  width: 100%;
  top: -20px;
  right: 6px;
  position: absolute;
  height: 145px;
}

.theARC {
  fill: #e9c32b;
  opacity: 1;
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.5));
}

#theicSVI {
  position: absolute;
  top: -15px;
  transform: translateY(50%);
  filter: drop-shadow(.2px 0px 1px black);
}

.CatPermis {
  font-size: 32px;
  color: #14689f;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.411);
  text-align: center;
}


.theContConD {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.thedFormC {
  display: flex;
  width: 35%;
}

.thedFormC form {
  margin: 25px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.theCdetailsInfosC {
  display: flex;
  width: 55%;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}

.cp {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px;
}

.cp input {
  border: 2px solid #14689f;
  background: white;
  border-radius: 4px;
  padding: 8px;
  font-size: 18px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

textarea {
  width: 100%;
  border: 2px solid #14689f;
  background: white;
  border-radius: 4px;
  padding: 8px;
  font-size: 18px;
  box-sizing: border-box;
  min-width: 90%;
  max-width: 100%;
  min-height: 120px;
  max-height: 300px;
}

.lC {
  color: #f7c615;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.201);
  font-size: 18px;
  margin-bottom: 5px;
}

.miniRPD {
  font-size: 12px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: 100;
  text-orientation: sideways;
}

.theCdetailsInfosC span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #38a3ff;
  filter: drop-shadow(1px 1px 1px black);
  margin: 10px;
}

.theBTNsendmes {
  background: #38a3ff;
  color: #003c4d;
  appearance: none;
  padding: 8px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.154);
  margin-top: 8px;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  /* box-shadow: 1px 1px 4px 0px #00000080; */
  filter: drop-shadow(1px 1px 1px rgba(150, 161, 0, 0.546));
}

.compINF {
  display: flex;
  flex-direction: row;
  margin: 25px 0;
  width: 50%;
}

.tCinf {
  font-size: 22px;
  margin-bottom: 10px;
  color: #f7c615;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.164);

}

.GpageMPORM {
  min-height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}



.GpageMPORM .widthMaxcont {
  height: calc(100%);
}

.GpageMPORM .lay-formations {
  min-height: calc(100vh - 220px);
}

.GpageMPORM .lay-formules::before {
  display: none;
}

.GpageMPORM .chXo:nth-child(1) {
  animation: float3d1 10s ease-in-out infinite;
}

.GpageMPORM .chXo:nth-child(2) {
  animation: float3d2 10s ease-in-out infinite;
}

.GpageMPORM .chXo:nth-child(3) {
  animation: float3d3 10s ease-in-out infinite;
}

.GpageMPORM .chXo:nth-child(4) {
  animation: float3d4 10s ease-in-out infinite;
}

.GpageMPORM .chXo:hover {
  animation: none;
}

.contForm {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  min-height: calc(100vh - 70px);

}

.animationClass {
  opacity: 1;
  animation: bouncIIN2 1s ease forwards;
}

.animationClass2 {
  opacity: 1;
  animation: bounce .6s ease forwards;
}

.animationClass2:nth-child(2) {
  animation-delay: .2s;
}

.animationClass2:nth-child(3) {
  animation-delay: .4s;
}
.posA {
  position: absolute;
  top: 0;
  width: 75%;
  opacity: 0;
}
.titnJ {
  text-align: center!important;
}


.aGalB {
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 5px 5px 0 5px;
  font-size: 18px;
  color: #003c4d;
  text-shadow: 1px 1px 1px #003c4d36;
}

.anim {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  /* -webkit-animation-fill-mode: both;
  animation-fill-mode: both */
}

.ulind li:nth-child(1) .anim {
  animation-delay: .2s !important;
}

.ulind li:nth-child(2) .anim {
  animation-delay: .4s !important;
}

.ulind li:nth-child(3) .anim {
  animation-delay: .6s !important;
}

.ulind li:nth-child(4) .anim {
  animation-delay: .8s !important;
}

.ulind li:nth-child(5) .anim {
  animation-delay: 1s !important;
}

.contLaFcat {
  margin-top: 20px;
}

.thebtnTretF {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: #003c4d;
}

.tiFormSele {
  margin: 25px 0;
}

.titpForm {
  text-align: left;
  font-size: 26px;
  margin-bottom: 15px;
  color: #173c4d;
}

.contDFormation {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 25px;
}


.seleOptionCat {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;
}

.partG,
.partD {
  width: 50%;
}

.partD {
  display: flex;
  justify-content: center;
  align-items: center;
}

.desriptionFormation,
.conditionsFormation,
.pjfournirFormation,
.vehiculesFormation {
  padding: 10px;
  box-sizing: border-box;
  position: relative;
}

.optionCAT,
.optionCODE {
  min-width: 50%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.btnCon {
  font-size: 26px;
  cursor: pointer;
  appearance: none;
  background: transparent;
  border: none;
  font-family: monospace;
}

.btnC {
  font-size: 26px;
  cursor: pointer;
  appearance: none;
  background: transparent;
  border: none;
  font-family: monospace;
}

.selB {
  background: #cacaca;
  padding: 8px;
  border-radius: 8px;
  color: #173c4d;
  text-shadow: 0px 0px 1px black;
}

.titOP {
  text-align: center;
}

.seleOptionCat ul {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.desriptionFormation {
  width: 100%;
}

.conditionsFormation {
  width: 100%;
}

.pjfournirFormation {
  width: 100%;
}

.vehiculesFormation {
  width: 100%;
}

.conditionsFormation ul,
.vehiculesFormation ul,
.pjfournirFormation ul {
  padding-left: 20px;
}

.theCOMPFORTIT {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.justIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(.2px 0px 1px black);
}


.contDFormation p,
.contDFormation li,
.seleOptionCat li {
  font-size: 20px;
  text-align: left;
  padding: 5px 10px;
  position: relative;
  list-style: none;
  font-family: Moderat, sans-serif;
}

.ulCA li:before {
  content: "\2713";
  color: #f7c615;
  font-weight: 800;
  font-size: 30px;
  position: absolute;
  top: -3px;
  left: -22px;
}

.ulV li:before {
  content: "\25BA";
  color: #f7c615;
  font-weight: 800;
  font-size: 24px;
  position: absolute;
  top: 1px;
  left: -22px;
}

.ulPF li:before {
  /* content: "\1F5CE"; */
  content: "\2022";
  color: #f7c615;
  font-weight: 800;
  font-size: 30px;
  position: absolute;
  top: -2px;
  left: -15px;
}

/* .ulPF {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.ulPF li{
 width: 32%; 
} */
.CompAge {
  position: absolute;
  top: 0;
  right: 20px;

}

.CompAge ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.CompAge ul li button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border: 1px solid #003c4d;
  background: #38a3ff;
  width: 40px;
  height: 40px;
  appearance: none;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.CompAge p {
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 16px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.selAGE button {
  background: #f7c615 !important;
  transform: scale(1.2);
  transition: all .7s;
}

.listeDeFormules {
  bottom: 0px;
  display: flex;
  flex-direction: row;
  width: 80%;
  left: 20px;
  position: relative;
}

.listeDeFormules .fomruleDansF {
  width: 100%;
  position: absolute;
  opacity: 1;
  transition: all .7s;
}

.fomruleDansF {
  width: 80%;
}

.cursp {
  cursor: pointer;
}

.LaFormule {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  min-height: 390px;
  box-shadow: -2px 6px 13px 3px rgb(0 0 0 / 45%);
  -webkit-box-shadow: -2px 6px 13px 3px rgb(0 0 0 / 45%);
  margin: 5px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  /* background: white; */
  box-shadow: inset 1px -1px 6px 1px rgb(255 255 255 / 20%), 2px 3px 7px 4px #41414161;
  background: linear-gradient(197deg, #0e0e0e, #0069b3);
}

.tdFO {
  /* background-color: #14689F; */
  padding: 15px 0;
  position: relative;
  overflow: hidden;
}

.LaFormule b {
  color: white;
}

.tFORFAIToneF {
  color: white;
  margin: 15px 5px;
  text-align: center;
  border-bottom: none;
  font-size: 20px;
}

.tdFO span {
  font-size: 16px;
  color: white;
}

.codeUL {
  display: flex;
  flex-direction: column;
  border-top: 3px solid rgb(255 255 255 / 75%);
  /* border-bottom: 3px solid rgb(255 255 255 / 75%); */
}

.listeF {
  padding: 0;
  margin: 0;
}

.titecodechoiceinfor {
  font-size: 14px;
  color: rgb(255, 255, 255);
  margin: 0;
  padding: 10px 0 0 0;
  /* border-top: 3px solid rgb(255 255 255 / 75%); */
}

.CatCodeListInForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 5px 5px 5px 5px;
  width: 100%;
}

#selCodefsp {
  background: linear-gradient(26deg, #352a01, #ffd640);
  box-shadow: 0px -1px 4px 0px #ffffff52;
  color: white;
  font-weight: 600;
}

.CatCodeListInForm span {
  font-size: 12px;
  font-weight: 600;
  margin: 10px;
  width: 25%;
  padding: 10px;
  border: 1px inset #bdbdbdbf;
  border-radius: 20px;
  background: white;
  cursor: pointer;
}

.liF {
  list-style: none;
  padding: 0;
  color: #000;
  padding: 10px 25px !important;
  list-style: none;
  border-bottom: 1px solid #efefef4a;
  position: relative;
  text-align: center;
}

.liF p,
.liF span {
  font-size: 18px;
}

.contentLiF {
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: space-between;
  align-items: center;
}

.contentLiF p {
  margin: 2px;
  text-align: left;
  width: 85%;
}

.priceFORFAITcode {
  font-size: 20px;
  font-weight: 200;
  padding: 0 0 10px 0;
  margin: 0;
  color: #ffffffa2;
  border-bottom: 3px solid rgb(255 255 255 / 75%);
  text-align: center !important;
}

.liF:last-child {
  border-bottom: none;
}

.priceFORFAIT {
  font-size: 38px !important;
  font-weight: 700;
  padding: 0;
  margin: 10px 0;
  color: #d7d100;
  text-align: center !important;
}

/* #ForMbg {
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  background: url(./assets/img/wallpaper/route-beton-brun-pendant-journee-smb.jpg);
} */

/* .brIT {
  position: relative;
}

.brIT::after {
  display: block;
  content: '🏎️';
  font-size: 40px;
  transform: scale(-1, 1);
  z-index: -1;
  position: absolute;
  top: -45px;

} */

.typing-effect {
  font-family: monospace;
  font-size: 24px;
  overflow: hidden;
  display: inline-block;
  /* border-right: 2px solid black; */
  /* Cursor effect */
}

.CompAge ul {
  padding-left: 0;
}

.menu-button {
  cursor: pointer;
  z-index: 1000;
  /* Make sure the button is above the menu */
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 35px;
  height: 30px;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: #ffffff;
  transition: 0.3s;
  border-radius: 20px;
  filter: drop-shadow(1px 1px 1px black);
}

.menu-button.open .bar1 {
  transform: rotate(-45deg) translate(-9px, 9px);
  background-color: #d20f0fc9;
}

.menu-button.open .bar2 {
  opacity: 0;
}

.op0 {
  transform: translate(2000px, 0px) !important;
}

.op1 {
  transform: translate(2000px, 65px) !important;
}

.op2 {
  transform: translate(2000px, 130px) !important;
}

.menu-button.open .bar3 {
  transform: rotate(45deg) translate(-8px, -8px);
  background-color: #d20f0fc9;
}


.cursor {
  animation: blink 1s infinite;
}

.desIMG p {
  margin-bottom: 15px;
}

.TCDGF,
.theMPORMw h1,
.theMPORMw h2,
.theMPORMw h3 {
  color: #f7c615;
  text-shadow: 0px 0px 2px rgb(0 0 0 / 20%);
}

.TCDGF {
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 70px;
}

.theMPORMw h2 {
  font-size: 2em;
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: left;
}

.theMPORMw h3 {
  font-size: 1.6em;
  margin-bottom: 10px;
}

.theMPORMw ul {
  padding-left: 25px;
}

.theMPORMw p {
  font-size: 1.1em;
  margin-bottom: 10px;
  text-align: justify;
}

.theMPORMw b {
  font-weight: bold;
  color: #0069b3;
  text-shadow: 0px 0px 1px black;
}

.titR {
  font-style: italic;
  font-size: 1.9vh;
  color: gray;
}

.theMPORMw a {
  color: #0056b3;
  text-decoration: none;
}

.theMPORMw a:hover {
  text-decoration: underline;
}

.theBmed {
  display: flex;
  justify-content: center;
  margin: 25px 0;
}
.compINF a {
  text-decoration: none;
  color: white;
}

.bMED {

  background: #f7c615;
  color: #000000;
  text-align: center;
  text-decoration: none;
  font-size: 1.8vh;
  padding: 20px;
}

.msgAL {
  text-align: center;
}

.errMSG {
  color: red;
}

.emptyIn {
  border-color: red !important;
}

.emptyIn::after {
  display: block;
  content: "Champ obligatoire";
  font-size: 14px;
  position: absolute;
  color: white;
  background-color: red;
  padding: 4px;
  top: 100%;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 999;
}

@media only screen and (max-width: 1200px) {


  .desIMG {
    background: rgb(184 184 184 / 63%);
    padding: 10px 8px;
    box-sizing: border-box;
    text-shadow: 0px 0px 1px #000000ba;
    font-weight: 400;
    font-size: 18px;
    max-width: 600px;
    border-radius: 20px;
  }

  .lay-presentation {
    height: 540px;
  }

  .thegalcsrsl {
    padding: 0;
  }

  .infroDcrsl {
    width: 100%;
    align-items: center;
  }

  .THEdesLOO {
    width: 100%;
    justify-content: center;
  }

  .dCSL {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 62%;
    height: 605px;
    position: relative;
    top: -98px;
    left: 78px;
    overflow: hidden;
    transform: rotate(20deg) scale(1);
    border-right: 3px solid #f7c615;
    border-left: 3px solid #f7c615;
    box-shadow: 2px 3px 7px 0px #00000073;
  }

  .cutDCSL {
    display: block;
    width: 100%;
    height: 505px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    transform: rotate(0deg);
  }


  .dCSL {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 800px;
    position: relative;
    top: -144px;
    left: 18%;
    overflow: hidden;
    transform: rotate(20deg) scale(1);
    border-right: 3px solid #f7c615;
    border-left: 3px solid #f7c615;
    box-shadow: 2px 3px 7px 0px #00000073;
  }

  .widMaxIMG {
    height: 615px;
  }

  .glisseMEGgal {
    display: flex;
    overflow: auto;
    width: 100%;
  }

  .GpageMPORM .lay-formations {
    min-height: calc(100vh - 145px);
  }

  .shadowbottom {
    width: 780px;
  }

  .ulind ul {
    padding: 0px 40px;
  }

  .nuAGR {
    width: auto;
    position: absolute;
    top: auto;
    left: 0;
    bottom: 12px;
    font-size: 12px;
    width: 100%;
  }

  .text-pres {
    text-align: left;
  }

  .theGpartAE {
    display: flex;
    justify-content: space-around;
    padding: 40px 0;
  }

  .leftAE {
    width: 45%;
  }

  .rightAE {
    width: 35%;
    box-shadow: none;
    border-radius: 5px;
    filter: drop-shadow(2px 4px 6px black);
  }

  .theVitZP {
    background-color: white;
    width: 100%;
    height: auto;
  }

  .theContHor {
    flex-direction: column;
    padding: 25px 0;
  }

  .allDaysSem {
    width: 500px;
    margin-top: 0;
  }

  .allDaysSem h2 {
    font-size: 20px;
  }

  .theCdetailsInfosC {
    flex-direction: column;
  }

  .compINF {
    width: 100%;
  }

  .thedFormC {
    width: 50%;
  }

  .thesecR:before,
  .thesecR:after {
    display: none;
  }

  .lay-footer {
    border-top: 1px solid white;
  }

  .rgFooter {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .rgFooter li {
    margin-bottom: 4px;
  }

  .GpageMPORM .widthMaxcont {
    width: 100%;
  }

  .contDFormation {
    flex-direction: column-reverse;
  }

  .partG,
  .partD {
    width: 100%;
  }

  .fomruleDansF {
    width: 500px;
    margin-bottom: 15px;
  }
  .desriptionFormation p{
    width: 100%!important;
}



}



@media only screen and (max-width: 720px) {

  .menu-button {
    display: flex;
  }

  .thenavH {
    z-index: -999;
    height: auto;
    transform: translateY(-999px);
    opacity: 0;
    transition: all .3s;
    position: fixed;
    background: #003c4d;
    width: 100%;
    top: 70px;
    right: 0;
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
    box-shadow: 0px 4px 6px 0px rgb(0 0 0 / 60%);
  }

  .thenavH.open {
    transform: translateY(0);
    display: block;
    opacity: 1;
  }

  .thenavH ul {
    flex-direction: column;
  }

  .thenavH ul li {
    margin: 5px 0;
    padding: 15px 0;
    text-align: center;
  }

  .widthMaxcont,
  .infroDcrsl,
  .desIMG {
    width: 100%;
  }


  .rightAE {
    width: 100%;
    margin-top: 30px;
    box-shadow: none;
    border-radius: 5px;
    filter: drop-shadow(2px 4px 6px black);
  }

  .typing-effect {
    font-size: 20px;
    text-align: center;
  }

  .theDico span {
    font-size: 14px;
  }

  .theDico svg {
    height: 50px;
    width: 50px;
  }

  .theContHor {
    flex-direction: column;
    padding: 25px 0;
  }

  .ulind ul {
    padding: 0;
  }

  .allDaysSem {
    width: 100%;
    margin-top: 0;
  }

  .allDaysSem h2 {
    font-size: 20px;
  }

  .Day span {
    font-size: 14px;
  }
  .priceFORFAIT{
    width: 100%!important;
  }

  .desIMG {
    background: rgba(255, 255, 255, 0.451);
    padding: 10px 8px;
    box-sizing: border-box;
    text-shadow: 0px 0px 1px #000000ba;
    font-weight: 400;
    font-size: 18px;
  }
  .liAGE {
    padding: 0 8px!important;
    width: auto!important;
  }
  .CompAge p {
    width: 100%!important;
  }
  .lay-presentation {
    height: 411px;
  }

  .thegalcsrsl {
    padding: 0;
  }

  .dCSL {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 62%;
    height: 605px;
    position: relative;
    top: -98px;
    left: 78px;
    overflow: hidden;
    transform: rotate(20deg) scale(1);
    border-right: 3px solid #f7c615;
    border-left: 3px solid #f7c615;
    box-shadow: 2px 3px 7px 0px #00000073;
  }

  .cutDCSL {
    display: block;
    width: 100%;
    height: 390px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    transform: rotate(0deg);
  }

  .dCSL {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 62%;
    height: 605px;
    position: relative;
    top: -98px;
    left: 78px;
    overflow: hidden;
    transform: rotate(20deg) scale(1);
    border-right: 3px solid #f7c615;
    border-left: 3px solid #f7c615;
    box-shadow: 2px 3px 7px 0px #00000073;
  }

  .widMaxIMG {
    height: 440px;
  }

  .glisseMEGgal {
    display: flex;
    overflow: auto;
    width: 100%;
  }

  .GpageMPORM .lay-formations {
    min-height: calc(100vh - 145px);
  }

  /* .dCSL {
    position: unset;
    left: 0;
    width: 100%;
    height: 1100px;
    border-right: none;
    border-left: none;
    transform: rotate(0deg);
  }



  .widMaxIMG {
    width: auto;
    padding: 0;
    height: 100%;
    transform: rotate(0deg) translateX(0px) translateY(0px);
  }

  .ef {
    transform: rotate(0deg) translateX(0px) translateY(0px);
    animation: bouncIItel 1.5s ease-in-out forwards;
  } */

  .infroDcrsl {
    align-items: center;
  }

  .slog {
    font-size: 14px;
    text-align: center;
    margin-top: 5px;
  }

  .aGalB {

    font-size: 12px;
    padding: 5px 2px 0 2px;
  }

  .thegalcsrsl {
    flex-direction: column;
  }

  .ligneForfait {
    flex-direction: column;
  }

  .nuAGR {
    width: auto;
    position: absolute;
    top: auto;
    left: 0;
    bottom: 12px;
    font-size: 12px;
    width: 100%;
  }

  .text-pres {
    text-align: left;
    font-size: 18px;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12);
    font-family: Moderat, sans-serif !important;
  }

  .GpageMPORM h2 {
    font-size: 1.5em;
  }

  .theContAvis {

    min-height: 80vh;
  }

  .GalAvis {
    width: 100%;
  }

  .btnGalc {
    padding: 0;
  }

  .lavisTTxt {
    text-align: left;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.211);
    font-size: 20px;
    margin-bottom: 10px;
  }

  .theGpartAE {
    flex-direction: column;
  }

  .leftAE {
    width: 100%;
  }

  .theGpartAE {
    justify-content: space-evenly;
    align-items: center;
    min-height: 750px;
  }

  .tiFormSele {
    margin: 25px 0;
    font-size: 16px;
  }

  .lapartC {
    margin-bottom: 45px;
  }

  .chXo {
    width: 145px;
    margin: 15px 5px;
    padding: 3px;
  }

  .lay-formations .widthMaxcont {
    margin: 0;
    padding: 0;
  }

  .contDFormation {
    flex-direction: column-reverse;
  }

  .partG,
  .partD {
    width: 100%;
  }

  .fomruleDansF {
    width: 90%;
    margin-bottom: 15px;
  }

  .thebtnTretF svg {
    width: 20px;
    height: 20px;
  }

  .titFormuu {
    font-size: 14px;
    text-shadow: 0 0 1px rgb(0 0 0 / 30%);
  }

  .liF p,
  .liF span {
    font-size: 16px !important;
  }

  .contDFormation li {
    width: 100%;
  }
  .contDFormation p {
    width: 80%;
  }
  .contDFormation .contentLiF span {
    width: 20%;
    text-align: right;
}
  .contDFormation .priceFORFAITcode {
    width: 100%;
  }

  .contDFormation p,
  .contDFormation li {
    padding: 5px 0;
  }

  .btnCon,
  .btnC {
    font-size: 14px;
  }


  .CompAge p {
    font-size: 10px;
    text-align: center;
  }

  .CompAge {
    position: unset;
  }

  .CompAge ul li button {
    color: black;
    width: 40px;
    height: 40px;
    font-size: 14px;
  }

  .seleOptionCat {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    align-items: center;
  }

  .optionCAT,
  .optionCODE {
    width: 100%;

  }

  .optionCAT {
    margin-bottom: 25px;
  }



  .lay-footer {
    border-top: 1px solid white;
  }

  .thesecR:before,
  .thesecR:after {
    display: none;
  }

  .thedFormC {
    width: 100%;
  }

  .rgFooter {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .rgFooter li {
    margin-bottom: 4px;
  }

  .theContConD {
    flex-direction: column-reverse;
  }

  .theCdetailsInfosC {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
  }


  .compINF {
    flex-direction: row;
    width: 100%;
  }

  .lay-entetepresFormation .justIcon svg {
    width: 75px;
    height: 75px;

  }
}

@media only screen and (max-width: 425px) {
  .infDimaC {
    transform: translate(0px, 0px) scale(.7) !important;
  }

}